import React from "react"
import { motion } from "framer-motion"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import OGMobile from "../../images/og-mobile.png"
import FeatureTabs from "../../components/feature-tabs"
import Particles from "../../components/particles"
import Slide1 from "../../images/never-miss-page/Image-1.png"
import Slide2 from "../../images/never-miss-page/Image-2.png"

const NeverMissAPage = () => (
  <div className="grad-layout">
    <Layout>
      <Seo
        title="Never Miss A Page"
        description="Download the free mobile app for iOS or Android for quick access to your team's incidents and schedules. Respond with ease."
        ogImage={OGMobile}
      />
      <section>
        <div className="container top-container">
          <div className="hero--section" style={{ position: "relative" }}>
            <h1
              className="main-title mobile-landing-title"
              style={{
                fontSize: "2.8em",
                lineHeight: "1.2",
                paddingLeft: "0.5em",
                borderLeft: "12px #FFC634 solid",
                maxWidth: "450px",
                textAlign: "left",
              }}
            >
              Don’t miss out on life just because you’re on call.
            </h1>
          </div>
          <FeatureTabs
            className="product-tabs columns"
            tabs={[
              {
                text: "Customize your notification preferences once and carry your phone like you normally do. We’ll take care of the rest.",
                image: Slide1,
              },
              {
                text: "Setup Critical Alerts to bypass Do Not Disturb with adjustable alert volumes and feel confident you're always reachable.",
                image: Slide2,
              },
            ]}
          />
        </div>

        <Particles />
      </section>
      <section
        style={{
          backgroundColor: "#fff",
          padding: "3em",
        }}
        className="section--city"
      >
        <div className="container container--call-out">
          <h3
            style={{
              fontSize: "35px",
              fontWeight: "bold",
              margin: "1.5em 0 0.4em 0",
              maxWidth: "700px",
              lineHeight: "40px",
            }}
          >
            Sign up for the incident managment system of tomorrow
          </h3>
          <p
            style={{
              maxWidth: "500px",
              fontSize: "20px",
              marginBottom: "1.3em",
              textAlign: "center",
            }}
          >
            Get started by signing up for a <em>free</em> 14-day trial. There's
            no need for a credit card, contracts, or cancellation fees.
          </p>
          <div className="mt-1">
            <div className="d-f">
              <motion.div
                whileHover={{ scale: 0.98 }}
                whileTap={{ scale: 1 }}
                className="mt-2 btn--container"
              >
                <a
                  className="btn btn--primary"
                  href="https://app.readyfive.io/signup"
                >
                  Try it free for 14 days
                </a>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  </div>
)

export default NeverMissAPage
